import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import HubspotForm from "react-hubspot-form"

export default function DownloadPLGWhitepaper() {
  return (
    <Layout
      title="Download the whitepaper on support automation for PLG"
      keywords="whitepaper, product led growth, support automation"
      description="Whitepaper on delivering real-time product insights to customer support, customer success and product management teams to increase sales conversions, product adoption and accelerate subscription revenue."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <script
          charSet="utf-8"
          src="https://js.hsforms.net/forms/shell.js"
        ></script>
        <link
          rel="canonical"
          href="https://irisagent.com/plg-whitepaper-download/"
        />
      </Helmet>
      <section className="inner-page" style={{ margin: "2% 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <h1>Whitepaper: The Business Case for AI powered Support Automation for Product-Led Growth</h1>
                <p><br/>
                   PLG strategy now puts the product at the forefront of customer journey to drive conversion, adoption, retention, and expansion.

 <br/><br/>This whitepaper is your recipe for delivering real-time product insights cross-functionally to increase conversions, and adoption to accelerate subscription revenue.
                </p>
                <img
                  src="/img/PLG whitepaper.png"
                  className="img-fluid"
                  alt="PLG Whitepaper"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                {/* <HubspotForm
                  portalId='7494256'
                  formId='29f4e374-b150-48db-9a15-668eeac19625'
                  loading={<div>Loading...</div>}
                /> */}
                <HubspotForm
                  portalId='7494256'
                  formId='0775c46e-8b5c-4735-938e-0bf516b49303'
                  loading={<div>Loading...</div>}
                />

              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
